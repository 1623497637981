<template>
  <vs-chip class="ag-grid-cell-chip" transparent :color="color(params.value)">
    <span>{{ name(params.value) }}</span>
  </vs-chip>
</template>

<script>
import { getStatusName, getStatusColor } from '@/enums/UserStatus';

export default {
  name: 'CellRendererStatus',
  computed: {
    name() {
      return (val) => getStatusName(val);
    },
    color() {
      return (val) => getStatusColor(val);
    },
  },
};
</script>
