<template>
  <span>{{ name(params.value) }}</span>
</template>

<script>
import { getRoleName } from '@/enums/UserRole';

export default {
  name: 'CellRendererRole',
  computed: {
    name() {
      return (val) => getRoleName(val);
    },
  },
};
</script>
