<template>
  <vs-chip class="ag-grid-cell-chip" transparent :color="color(params.value)">
    <span>{{ name(params.value) }}</span>
  </vs-chip>
</template>

<script>
import {
  getVerificationStatusName,
  getVerificationStatusColor,
} from '@/enums/UserVerificationStatus';

export default {
  name: 'CellRendererVerified',
  computed: {
    name() {
      return (val) => getVerificationStatusName(val);
    },
    color() {
      return (val) => getVerificationStatusColor(val);
    },
  },
};
</script>
